import { gql } from '@apollo/client';

export const UserFragment = gql`
  fragment UserFragment on User {
    _id
    slug
    name
    email
    dni
    dniType
    source
    privilege
    active
    client {
      _id
      phone
      promos {
        code
        discount
        name
      }
      preferredAddress {
        _id
        state
        city
        municipality
        neighborhood
        street
        house
        reference
        type
      }
      shopCart {
        _id
        items {
          _id
          quantity
          name
          photo
          code
          time
          price
          product {
            _id
            name
            category {
              name
            }
            subcategory
            description
          }
          options {
            title
            option
            price
          }
        }
      }
      addresses {
        _id
        state
        city
        municipality
        neighborhood
        street
        house
        reference
        type
      }
      orders {
        _id
      }
    }
  }
`;

export const CategoryFragment = gql`
  fragment CategoryFragment on Category {
    _id
    slug
    name
    icon
    subcategories {
      _id
      name
      icon
    }
    active
  }
`;

export const OptionFragment = gql`
  fragment OptionFragment on Option {
    _id
    title
    type
    active
    maxChoices
    minChoices
    items {
      _id
      name
      price
      active
    }
  }
`;

export const ProductFragment = gql`
  fragment ProductFragment on Product {
    _id
    slug
    code
    name
    priority
    category {
      ...CategoryFragment
    }
    subcategory
    description
    price
    limitPerOrder
    compareAtPrice
    tags
    options {
      ...OptionFragment
    }
    time
    timeGrowthRate
    limitPerHour
    limitPerOrder
    photos
    active
    available
    taxes {
      _id
      name
    }
  }
  ${CategoryFragment}
  ${OptionFragment}
`;

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    _id
    state
    city
    municipality
    neighborhood
    street
    house
    reference
    type
  }
`;

export const ClientFragment = gql`
  fragment ClientFragment on Client {
    _id
    phone
    promos {
      code
      discount
      name
    }
    shopCart {
      _id
      items {
        quantity
        ...ProductFragment
      }
    }
    preferredAddress {
      ...AddressFragment
    }
    addresses {
      ...AddressFragment
    }
    orders {
      _id
    }
    user {
      ...UserFragment
    }
  }
  ${AddressFragment}
  ${UserFragment}
  ${ProductFragment}
`;

export const OrderFragment = gql`
  fragment OrderFragment on Order {
    _id
    code
    status
    stage
    source
    deliveryMethod
    automatedPaymentCode
    charge {
      reference
      method
      bank
      photo
      amount
      commission
      country
      metadata
      currency {
        slug
        name
        symbol
        rate
      }
    }
    commission
    eta {
      date
      hour
      minute
    }
    shipping {
      slug
      name
      municipality
      neighborhoods
      price
      time
      _id
    }
    preparationTime
    immediate
    schedule {
      date
      hour
      minute
    }
    paid
    amountPaid
    subtotal
    taxes {
      name
      value
      _id
    }
    extraFees
    total
    products {
      _id
      quantity
      name
      photo
      code
      time
      price
      limitPerOrder
      product {
        _id
        name
        category {
          name
        }
        subcategory
        description
      }
      options {
        title
        option
        price
      }
    }
    invoiceAddress {
      ...AddressFragment
    }
    address {
      ...AddressFragment
    }
    rider {
      ...UserFragment
    }
    charge {
      _id
    }
    notes {
      _id
      active
      fromClient
      text
    }
    history {
      message
      type
      _id
    }
    createdAt
  }
  ${AddressFragment}
  ${UserFragment}
`;
