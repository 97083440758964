import React from 'react';
import Link from 'next/link';
import InstagramIcon from '../icons/InstagramIcon';
import FacebookIcon from '../icons/FacebookIcon';
import MarExpressIcon from '../icons/MarExpressIcon';
import TwitterIcon from '../icons/TwitterIcon';
import AppleIcon from '../icons/AppleIcon';
import GooglePlayIcon from '../icons/GooglePlayIcon';
import useSettings from '../../hooks/useSettings';

function Footer() {
  const [settings] = useSettings();
  return (
    <footer className="w-full block font-avenir">
      <div className="w-full container mx-auto">
        <div className="w-full flex flex-col sm:flex-row py-10 sm:justify-start justify-center">
          <div className="flex flex-col w-full sm:w-1/4">
            <MarExpressIcon className="w-20 h-auto text-font-white mb-4 ml-2" />
            <div className="w-full flex flex-row text-font-white justify-evenly md:justify-start my-5 md:my-0">
              <a
                href="https://www.instagram.com/marexpress.ve/?hl=es"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon className="w-6 h-6 mr-4 hover:text-primary-50" />
              </a>
              <a
                href="https://es-la.facebook.com/FestejosMARve/"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon className="w-6 h-6 mr-4 hover:text-primary-50" />
              </a>
              <a
                href="https://twitter.com/festejosmarvzla?lang=es"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon className="w-6 h-6 mr-4 hover:text-primary-50" />
              </a>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:flex-wrap w-full sm:w-3/4 mt-5 text-font-white text-sm lg:text-base items-start justify-end">
            <div className="flex flex-col w-full md:w-2/5 lg:w-1/4 mb-5 md:mb-0">
              <p className="font-bold mb-1">Mi perfil</p>
              <Link href="/">
                <a className="font-light mb-1 hover:text-primary-50">
                  Información
                </a>
              </Link>
              <Link href="/">
                <a className="font-light mb-1 hover:text-primary-50">
                  Mis ordenes
                </a>
              </Link>
              <Link href="/">
                <a className="font-light mb-1 hover:text-primary-50">
                  Mis direcciones
                </a>
              </Link>
              <Link href="/">
                <a className="font-light mb-1 hover:text-primary-50">
                  Cambiar contraseña
                </a>
              </Link>
            </div>
            <div className="flex flex-col w-full md:w-2/5 lg:w-1/4 mb-5 md:mb-0">
              <p className="font-bold mb-1">Contáctanos</p>
              <p className="mb-1">04141089194</p>
              <p className="mb-1">marexpress@festejosmar.com</p>
              <p className="mb-1">
                {settings?.openHour} am - {settings?.closeHour} pm
              </p>
            </div>
            <div className="flex flex-col w-full md:w-2/5 lg:w-1/4 my-5 md:my-0">
              <a
                href="https://apps.apple.com/us/app/mar/id1622683035"
                target="_blank"
                rel="noreferrer"
                className="py-2 mb-5 flex flex-row bg-background-300 hover:bg-neutral-800 rounded-md justify-center"
              >
                <AppleIcon className="w-6 h-6 mr-4" />
                App Store
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.avilatek.marExpressMobile"
                target="_blank"
                rel="noreferrer"
                className="py-2 mb-2 flex flex-row bg-background-300 hover:bg-neutral-800 rounded-md justify-center"
              >
                <GooglePlayIcon className="w-6 h-6 mr-4" />
                Google Play
              </a>
            </div>
          </div>
        </div>

        <div className="w-full px-4 text-center pb-1">
          <div className="text-sm text-font-white">
            Copyright © {new Date().getFullYear()}{' '}
            <span className="text-font-white text-sm">
              MAR Express. Todos los derechos reservados
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
