import React from 'react';

function MarExpressIcon({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 534.000000 518.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,518.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M2203 4707 l-233 -212 0 -1961 0 -1961 208 -121 c114 -66 214 -124
223 -127 14 -5 17 8 22 92 4 54 7 486 7 961 l0 862 304 0 304 0 6 -402 c4
-222 10 -653 14 -958 7 -517 8 -555 24 -554 10 1 96 48 193 105 l175 103 0
1981 0 1980 -213 213 -212 212 -295 0 -295 -1 -232 -212z m670 -226 l142 -89
6 -128 c4 -71 10 -459 14 -861 l8 -733 -307 0 -306 0 0 880 0 879 133 70 c72
39 140 71 150 71 9 0 81 -40 160 -89z"
        />
        <path
          d="M3733 4623 c-8 -101 -9 -3861 0 -3870 7 -6 51 16 219 115 l148 87 0
326 c0 179 3 441 7 583 l6 257 101 -3 100 -3 262 -375 c188 -269 268 -375 281
-374 17 0 263 134 307 167 19 14 16 22 -91 208 -61 107 -170 295 -242 417 -72
123 -131 226 -131 229 0 4 39 47 88 97 157 164 223 316 242 551 33 426 -166
849 -591 1260 -207 200 -357 302 -549 374 -166 62 -147 67 -157 -46z m561
-755 c83 -84 180 -191 215 -238 178 -236 217 -461 116 -660 -60 -119 -189
-231 -366 -319 -125 -63 -144 -65 -153 -13 -8 49 -8 1276 0 1336 5 31 12 46
21 46 8 0 83 -69 167 -152z"
        />
        <path
          d="M1557 4601 c-210 -110 -194 -96 -221 -181 -14 -41 -82 -298 -151
-570 -70 -272 -132 -508 -139 -525 l-12 -29 -140 134 c-76 74 -160 154 -185
178 l-46 43 -52 -81 c-123 -192 -216 -428 -266 -675 -26 -125 -27 -140 -22
-390 4 -259 4 -261 40 -400 19 -77 54 -192 76 -255 42 -117 192 -430 216 -450
11 -9 20 -2 41 32 61 96 164 309 164 338 0 17 -14 58 -31 92 -50 100 -96 230
-119 338 -20 91 -22 122 -17 318 4 195 19 332 36 332 3 0 33 -43 66 -95 164
-260 237 -367 245 -359 9 9 141 359 235 621 26 73 53 140 61 150 11 15 13
-154 14 -1113 l0 -1132 188 -111 187 -111 8 437 c8 465 1 2816 -9 3266 -6 218
-9 267 -21 267 -7 0 -73 -31 -146 -69z"
        />
      </g>
    </svg>
  );
}

export default MarExpressIcon;
