import React from 'react';

function ShoppingCartIcon({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.94336 3.75H6.44336L6.94336 6.25L3.94336 3.75ZM8.94336 16.25H21.4434L26.4434 6.25H6.94336L8.94336 16.25ZM8.94336 16.25L6.94336 6.25L8.94336 16.25ZM8.94336 16.25L6.07711 19.1163C5.28961 19.9038 5.84711 21.25 6.96086 21.25H21.4434L8.94336 16.25ZM21.4434 21.25C20.7803 21.25 20.1444 21.5134 19.6756 21.9822C19.2068 22.4511 18.9434 23.087 18.9434 23.75C18.9434 24.413 19.2068 25.0489 19.6756 25.5178C20.1444 25.9866 20.7803 26.25 21.4434 26.25C22.1064 26.25 22.7423 25.9866 23.2111 25.5178C23.68 25.0489 23.9434 24.413 23.9434 23.75C23.9434 23.087 23.68 22.4511 23.2111 21.9822C22.7423 21.5134 22.1064 21.25 21.4434 21.25ZM11.4434 23.75C11.4434 24.413 11.18 25.0489 10.7111 25.5178C10.2423 25.9866 9.6064 26.25 8.94336 26.25C8.28032 26.25 7.64443 25.9866 7.17559 25.5178C6.70675 25.0489 6.44336 24.413 6.44336 23.75C6.44336 23.087 6.70675 22.4511 7.17559 21.9822C7.64443 21.5134 8.28032 21.25 8.94336 21.25C9.6064 21.25 10.2423 21.5134 10.7111 21.9822C11.18 22.4511 11.4434 23.087 11.4434 23.75V23.75Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShoppingCartIcon;
