import React from 'react';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import NProgress from 'nprogress';
import { SIGN_OUT } from '../../graphql/mutations';
import { useNotify } from '../../hooks';
import SignOutIcon from '../icons/SignOutIcon';

function UserDropdown() {
  const router = useRouter();
  const notify = useNotify();
  const [signOut] = useMutation(SIGN_OUT);

  const onClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      NProgress.start();
      await signOut();
      await router.push('/login');
    } catch (err) {
      notify(err.message, 'info');
    } finally {
      NProgress.done();
    }
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className="text-gray-600 block bg-transparent focus:outline-none outline-none rounded-full"
    >
      <div className="items-center flex">
        <span className="w-12 h-12 text-sm text-white bg-transparent inline-flex items-center justify-center rounded-full">
          <SignOutIcon className="fill-current w-1/2 text-neutral-400 hover:text-neutral-600" />
        </span>
      </div>
    </button>
  );
}

export default UserDropdown;
