import React from 'react';
import { Setting, Currency } from '../models';

export interface AppContextProps {
  settings?: Setting;
  setSettings?: React.Dispatch<React.SetStateAction<Setting>>;
  currencies?: Currency[];
  setCurrencies?: React.Dispatch<React.SetStateAction<Currency[]>>;
  activeCurrency?: Currency;
  setActiveCurrency?: React.Dispatch<React.SetStateAction<Currency>>;
  // plugins?: Plugin[];
  // setPlugins?: React.Dispatch<React.SetStateAction<Plugin[]>>;
}

const AppContext = React.createContext<AppContextProps>({});

export default AppContext;
