import { gql } from '@apollo/client';
import {
  ProductFragment,
  OptionFragment,
  UserFragment,
  ClientFragment,
  CategoryFragment,
  OrderFragment,
} from './fragments';

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES(
    $skip: Int
    $limit: Int
    $sort: SortFindManyCategoryInput
  ) {
    categories(skip: $skip, limit: $limit, sort: $sort) {
      ...CategoryFragment
    }
  }
  ${CategoryFragment}
`;

export const GET_CLIENT = gql`
  query GET_CLIENT($filter: FilterFindOneClientInput!) {
    client(filter: $filter) {
      ...ClientFragment
    }
  }
  ${ClientFragment}
`;

export const GET_CURRENCIES = gql`
  query GET_CURRENCIES($filter: FilterFindManyCurrencyInput) {
    currencies(filter: $filter) {
      _id
      name
      symbol
      rate
      active
      methods {
        _id
        name
        active
      }
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query GET_PAYMENT_METHODS($filter: FilterFindManyPaymentMethodInput) {
    paymentMethods(filter: $filter) {
      _id
      title
      note
      commission
      automated
      paymentData {
        name
        value
        _id
      }
      extraFields {
        type
        name
        _id
      }
      currency {
        _id
        name
        symbol
        rate
      }
      automated
      active
    }
  }
`;

export const GET_ORDER = gql`
  query GET_ORDER($filter: FilterFindOneOrderInput) {
    order(filter: $filter) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

export const GET_ORDERS = gql`
  query GET_ORDERS(
    $filter: FilterFindManyOrderInput
    $sort: SortFindManyOrderInput
  ) {
    orders(filter: $filter, sort: $sort) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

export const GET_PLUGINS = gql`
  query GET_PLUGINS {
    plugins {
      _id
      name
      active
      credentials {
        name
        value
        type
      }
    }
  }
`;

export const GET_PLUGIN = gql`
  query GET_PLUGIN($filter: FilterFindOnePluginInput) {
    plugin(filter: $filter) {
      _id
      name
      active
      credentials {
        name
        value
        type
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query GET_PRODUCT($filter: FilterFindOneProductInput) {
    categories(filter: { active: true }) {
      ...CategoryFragment
    }
    taxes(filter: { active: true }) {
      _id
      name
    }
    option(filter: { active: true }) {
      ...OptionFragment
    }
    product(filter: $filter) {
      ...ProductFragment
    }
  }
  ${CategoryFragment}
  ${ProductFragment}
  ${OptionFragment}
`;

// TODO: FIXME hay que unificar este query con el de dashboard lo que sucede es que home esta enredado con el query de productos y ya es la entrega
export const GET_PRODUCTS_PAGINATION = gql`
  query GET_PRODUCTS_PAGINATION(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyProductInput
    $sort: SortFindManyProductInput
  ) {
    productPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      items {
        ...ProductFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${ProductFragment}
`;

export const FILTER_PRODUCTS_CATEGORY_SUBCATEGORY_PAGINATION = gql`
  query FILTER_PRODUCTS_CATEGORY_SUBCATEGORY_PAGINATION(
    $data: FilterProductsPaginationInput
  ) {
    filterProductsPagination(data: $data) {
      items {
        ...ProductFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${ProductFragment}
`;

export const GET_PRODUCTS = gql`
  query GET_PRODUCTS($filter: FilterFindManyProductInput, $limit: Int) {
    products(filter: $filter, limit: $limit) {
      _id
      name
      active
      photos
      code
      description
      subcategory
      price
      limitPerOrder
      category {
        _id
        name
      }
      slug
      code
      priority
      subcategory
      compareAtPrice
      options {
        _id
        title
        items {
          _id
          name
          price
          active
        }
      }
      available
    }
  }
`;

export const GET_PROMO_CODE = gql`
  query GET_PROMO_CODE($filter: FilterFindOnePromoCodeInput) {
    promoCode(filter: $filter) {
      _id
      name
      code
      discount
      type
      expirationDate
      active
    }
  }
`;

export const GET_SHOPPING_CART = gql`
  query GET_SHOPPING_CART(
    $filter: FilterFindManyShopCartInput
    $sort: SortFindManyShopCartInput
    $limit: Int = 1
  ) {
    shopCarts(filter: $filter, sort: $sort, limit: $limit) {
      _id
      code
      client {
        _id
        phone
        promos {
          code
          discount
          name
        }
      }
      items {
        _id
        quantity
        name
        photo
        code
        time
        price
        limitPerOrder
        product {
          _id
          name
          category {
            name
          }
          subcategory
          description
        }
        options {
          _id
          title
          option
          price
        }
      }
    }
  }
`;

export const GET_ORDER_TOTAL = gql`
  query GET_ORDER_TOTAL($data: GetOrderTotalInput) {
    getOrderTotal(data: $data) {
      subtotal
      total
      deliveryFee
      discount
      commission
      tax
      preparationTime
      taxes {
        name
        value
      }
      eta {
        date
        hour
        minute
      }
    }
  }
`;

export const GET_VENEZUELA = gql`
  query GET_VENEZUELA {
    getVenezuela {
      venezuela {
        municipio
        capital
        parroquias
        sectores
      }
    }
  }
`;

export const CURRENT_CONFIG = gql`
  query CURRENT_CONFIG {
    currentSetting {
      carouselImages {
        _id
        title
        description
        url
        link
        linkType
      }
      orderLifespan
      scheduleMaxDays
      openHour
      closeHour
      pickupAddress
      active
      _id
    }
  }
`;
