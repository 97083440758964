import React from 'react';

function BagIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2489 11.6759V6.37964C17.2489 4.97497 16.6909 3.62784 15.6976 2.63459C14.7044 1.64134 13.3572 1.08334 11.9526 1.08334C10.5479 1.08334 9.20078 1.64134 8.20753 2.63459C7.21428 3.62784 6.65628 4.97497 6.65628 6.37964V11.6759H17.2489ZM2.68406 9.02779H21.2211L22.5452 24.9167H1.35999L2.68406 9.02779Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BagIcon;
