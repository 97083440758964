import React from 'react';
import { useRouter } from 'next/router';
import { useQuery, useMutation } from '@apollo/client';
import { CURRENT_USER } from '../graphql/queries';
import { SIGN_OUT } from '../graphql/mutations';
import { User } from '../models';
import { useNotify } from '../hooks';

export type TUserContext = {
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
};

export const UserContext = React.createContext<TUserContext>({});

interface UserContextProviderProps {
  children: React.ReactNode;
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const [user, setUser] = React.useState<User>(null);
  const context = React.useMemo(() => ({ user, setUser }), [user, setUser]);
  const router = useRouter();
  const notify = useNotify();
  const [signOut] = useMutation(SIGN_OUT);

  const { data, loading, error } = useQuery<{ currentUser: User }>(
    CURRENT_USER,
    {
      fetchPolicy: 'network-only',
    }
  );

  React.useEffect(() => {
    if (router.pathname !== '/terms-and-conditions') {
      if (!loading) {
        if (data) {
          // Si no hay usuario bloquear acceso a páginas de usuario
          if (
            data?.currentUser === null &&
            (router.pathname === '/my-orders/[_id]' ||
              router.pathname === '/profile' ||
              router.pathname === '/shopping-cart')
          ) {
            setUser(null);
            router.push('/login');
            notify('Por favor inicie sesión', 'info');
          } else {
            setUser(data?.currentUser);
          }
        }
        if (!data || error) {
          console.error(error);
          setUser(null);
          router.push('/login');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  React.useEffect(() => {
    if (user) {
      if (user?.privilege !== 0) {
        notify('No cuenta con los permisos necesarios', 'info');
        signOut();
        router.push('/login');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}
