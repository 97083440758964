/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import { ApolloQueryResult, useLazyQuery, useQuery } from '@apollo/client';
import { ShopCart } from '../models';
import { useUser } from '../hooks';
import { GET_SHOPPING_CART } from '../graphql/queries';

export interface CartContextProps {
  shopCart?: ShopCart;
  setShopCart?: React.Dispatch<React.SetStateAction<ShopCart>>;
  refetch?: (variables?: Partial<Record<string, any>>) => Promise<
    ApolloQueryResult<{
      shopCarts: ShopCart[];
    }>
  >;
}

export const CartContext = React.createContext<CartContextProps>({});

export function useShopCart(): readonly [
  ShopCart,
  React.Dispatch<React.SetStateAction<ShopCart>>
] {
  const { shopCart, setShopCart } = React.useContext(CartContext);
  return [shopCart, setShopCart] as const;
}

export function useRefetchShopCart() {
  const { refetch } = React.useContext(CartContext);
  return refetch;
}

interface CartContextProviderProps {
  children?: React.ReactNode;
}

export default function CartContextProvider({
  children,
}: CartContextProviderProps) {
  const [user] = useUser();
  const { data, loading, refetch } = useQuery<{ shopCarts: ShopCart[] }>(
    GET_SHOPPING_CART,
    {
      variables: {
        filter: {
          client: user?.client?._id,
        },
        sort: '_ID_DESC',
        limit: 1,
      },
      fetchPolicy: 'network-only',
    }
  );
  const [shopCart, setShopCart] = React.useState<ShopCart>({});

  React.useEffect(() => {
    if (!loading && data) {
      setShopCart(data?.shopCarts[0]);
    }
  }, [data, loading]);

  const value = React.useMemo(
    () => ({ shopCart, setShopCart, refetch }),
    [refetch, shopCart]
  );
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}
