import React from 'react';

function FacebookIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99967 18V10H5.33301V7.33333H7.99967V5.90133C7.99967 3.19 9.32034 2 11.5737 2C12.653 2 13.2237 2.08 13.4937 2.11667V4.66667H11.957C11.0003 4.66667 10.6663 5.17133 10.6663 6.194V7.33333H13.4697L13.089 10H10.6663V18H7.99967Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FacebookIcon;
