/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import UserDropdown from './UserDropdown';
import NotificationDropdown from './NotificationDropdown';
import MarExpressIcon from '../icons/MarExpressIcon';
import SearchIcon from '../icons/SearchIcon';
import HomeIcon from '../icons/HomeIcon';
import BagIcon from '../icons/BagIcon';
import UserIcon from '../icons/UserIcon';
import ShoppingCartIcon from '../icons/ShoppingCartIcon';
import { useUser } from '../../hooks';
import LoginIcon from '../icons/LoginIcon';

interface NavbarProps {
  search?: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  searchType?: 'search' | 'link' | 'none';
}

export default function Navbar({
  search,
  setSearch,
  searchType = 'search',
}: NavbarProps) {
  const [user] = useUser();
  const router = useRouter();
  return (
    <nav className="w-full z-10 lg:flex-row lg:flex-no-wrap lg:justify-start flex items-center p-4 bg-primary-300 text-font-white">
      <div className="w-full mx-auto flex md:flex-row items-center justify-between lg:px-10 px-4">
        <Link href="/">
          <a>
            <MarExpressIcon className="w-24 h-auto text-font-white" />
          </a>
        </Link>
        {searchType === 'search' ? (
          <div className="w-3/5 md:w-2/5 h-full flex flex-col font-avenir border-0 border-b border-primary-50  focus:ring-0">
            <div className="w-full flex flex-row items-center">
              <SearchIcon className="w-6 h-6 text-font-white cursor-pointer" />
              <input
                className="bg-transparent border-0 focus:ring-0 w-full text-font-white leading-tight outline-none placeholder:text-primary-50"
                name="searchInput"
                type="text"
                id="searchInput"
                value={search}
                onChange={(e) => {
                  e.preventDefault();
                  setSearch(e.target.value);
                }}
                placeholder="Ingrese su plato favorito"
              />
            </div>
          </div>
        ) : searchType === 'link' ? (
          <div className="w-3/5 md:w-2/5 h-full flex flex-col font-avenir border-0 border-b border-primary-50  focus:ring-0">
            <button
              className="w-full pb-2 flex flex-row items-center bg-transparent border-0 focus:ring-0 leading-tight outline-none"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                router.push('/');
              }}
            >
              <SearchIcon className="w-6 h-6 text-font-white cursor-pointer" />
              <p className="text-primary-50 ml-2">Ingrese su plato favorito</p>
            </button>
          </div>
        ) : null}

        <ul className="flex-row list-none items-center hidden md:flex space-x-4 lg:space-x-6">
          {/* <NotificationDropdown /> */}
          <Link href="/">
            <a>
              <HomeIcon
                className={`w-6 h-6 ${
                  router.asPath === '/'
                    ? 'text-secondary-200'
                    : 'text-primary-50 hover:text-primary-100'
                }`}
              />
            </a>
          </Link>
          {user ? (
            <>
              <Link href="/my-orders">
                <a>
                  <BagIcon
                    className={`w-6 h-6 ${
                      router.asPath === '/my-orders'
                        ? 'text-secondary-200'
                        : 'text-primary-50 hover:text-primary-100'
                    }`}
                  />
                </a>
              </Link>
              <Link href="/profile">
                <a>
                  <UserIcon
                    className={`w-6 h-6 ${
                      router.asPath === '/profile'
                        ? 'text-secondary-200'
                        : 'text-primary-50 hover:text-primary-100'
                    }`}
                  />
                </a>
              </Link>
              <Link href="/shopping-cart">
                <a className="p-1 bg-background-100 rounded-lg">
                  <ShoppingCartIcon className="w-7 h-7 text-secondary-300" />
                </a>
              </Link>
            </>
          ) : (
            <Link href="/login">
              <a>
                <LoginIcon
                  className={`w-6 h-6 ${
                    router.asPath === '/profile'
                      ? 'text-secondary-200'
                      : 'text-primary-50 hover:text-primary-100'
                  }`}
                />
              </a>
            </Link>
          )}
        </ul>
      </div>
    </nav>
  );
}
