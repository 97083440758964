import React from 'react';

function AppleIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.4523 7.82799C20.3539 7.88959 18.0115 9.18959 18.0115 12.072C18.1219 15.3592 20.9675 16.512 21.0163 16.512C20.9675 16.5736 20.5867 18.0824 19.4587 19.664C18.5635 21.0264 17.5699 22.4 16.0611 22.4C14.6259 22.4 14.1107 21.492 12.4547 21.492C10.6763 21.492 10.1731 22.4 8.81153 22.4C7.30273 22.4 6.23553 20.9528 5.29153 19.6032C4.06513 17.8368 3.02273 15.0648 2.98593 12.4032C2.96113 10.9928 3.23153 9.60639 3.91793 8.42879C4.88673 6.78479 6.61633 5.66879 8.50513 5.63199C9.95233 5.58319 11.2403 6.62559 12.1235 6.62559C12.9699 6.62559 14.5523 5.63199 16.3427 5.63199C17.1155 5.63279 19.1763 5.86559 20.4523 7.82799ZM12.0011 5.35039C11.7435 4.06239 12.4547 2.77439 13.1171 1.95279C13.9635 0.95919 15.3003 0.28479 16.4531 0.28479C16.5267 1.57279 16.0603 2.83599 15.2267 3.75599C14.4787 4.74959 13.1907 5.49759 12.0011 5.35039Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default AppleIcon;
