import React from 'react';

function TwitterIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.667 4.62467C18.029 4.908 17.3437 5.09867 16.6243 5.18467C17.359 4.74467 17.9223 4.048 18.1877 3.21734C17.501 3.62467 16.7397 3.92067 15.929 4.08067C15.2803 3.38934 14.3563 2.95734 13.3337 2.95734C11.3697 2.95734 9.77768 4.55 9.77768 6.51334C9.77768 6.792 9.80968 7.064 9.86967 7.32334C6.91434 7.17534 4.29434 5.75934 2.53967 3.60734C2.23434 4.13267 2.05901 4.74334 2.05901 5.396C2.05901 6.62934 2.68634 7.718 3.64034 8.35534C3.05767 8.33667 2.50901 8.17667 2.02967 7.91067C2.02967 7.926 2.02967 7.94 2.02967 7.95534C2.02967 9.67867 3.25501 11.116 4.88234 11.442C4.58434 11.5233 4.26967 11.5667 3.94501 11.5667C3.71634 11.5667 3.49301 11.544 3.27634 11.5033C3.72901 12.916 5.04234 13.9447 6.59834 13.9733C5.38167 14.9273 3.84834 15.496 2.18167 15.496C1.89501 15.496 1.61167 15.4793 1.33301 15.446C2.90701 16.4547 4.77567 17.0433 6.78434 17.0433C13.3257 17.0433 16.9017 11.6247 16.9017 6.92534C16.9017 6.77134 16.8983 6.618 16.8917 6.46534C17.587 5.96334 18.1903 5.33734 18.667 4.62467Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default TwitterIcon;
