import React from 'react';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  notMargin?: boolean;
  color?: string;
}

export default function Button({
  children,
  type,
  disabled,
  className,
  notMargin = false,
  color = 'primary',
  ...rest
}: ButtonProps) {
  if (color === 'success') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`py-1.5 px-8 text-font-white font-bold bg-success-300 hover:bg-success-200 active:bg-success-400 rounded-md focus:outline-none  disabled:opacity-50 ${className} ${
          notMargin ? '' : 'mr-3'
        }`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  if (color === 'warning') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`py-1.5 px-8 text-font-white font-bold bg-danger-300 hover:bg-danger-200 active:bg-danger-400 rounded-md focus:outline-none  disabled:opacity-50 ${className} ${
          notMargin ? '' : 'mr-3'
        }`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`py-1.5 px-8 text-font-white font-bold bg-primary-300 hover:bg-primary-200 active:bg-primary-400 rounded-md focus:outline-none  disabled:opacity-50 ${className} ${
        notMargin ? '' : 'mr-3'
      }`}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}
