import React from 'react';

function HomeIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.0128 13.5932L15.0928 1.19565C14.9883 1.09574 14.8495 1.04001 14.7052 1.04001C14.5609 1.04001 14.4221 1.09574 14.3176 1.19565L1.39758 13.5932C1.33797 13.6425 1.28915 13.7037 1.25418 13.7728C1.21921 13.842 1.19883 13.9176 1.19432 13.995C1.18981 14.0724 1.20126 14.1499 1.22797 14.2226C1.25468 14.2954 1.29606 14.3618 1.34953 14.4178C1.403 14.4737 1.46741 14.518 1.53872 14.5479C1.61004 14.5778 1.68673 14.5925 1.764 14.5913C1.84127 14.5901 1.91745 14.5729 1.98779 14.5408C2.05813 14.5086 2.1211 14.4623 2.17278 14.4047L14.7052 2.37905L27.2376 14.4047C27.3429 14.5096 27.4853 14.5685 27.6337 14.5685C27.7821 14.5685 27.9245 14.5096 28.0297 14.4047C28.083 14.351 28.1249 14.2871 28.153 14.2167C28.1811 14.1464 28.1948 14.0711 28.1932 13.9953C28.1917 13.9196 28.1749 13.8449 28.1439 13.7758C28.1129 13.7067 28.0683 13.6446 28.0128 13.5932Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M24.2547 14.5625C24.1057 14.5625 23.9628 14.6218 23.8575 14.7275C23.7521 14.8332 23.6929 14.9765 23.6929 15.126V25.833H18.0755V19.6342C18.0755 19.4847 18.0163 19.3414 17.911 19.2357C17.8057 19.13 17.6628 19.0707 17.5138 19.0707H11.8964C11.7474 19.0707 11.6045 19.13 11.4992 19.2357C11.3938 19.3414 11.3346 19.4847 11.3346 19.6342V25.833H5.71723V15.126C5.71723 14.9765 5.65805 14.8332 5.5527 14.7275C5.44735 14.6218 5.30447 14.5625 5.15549 14.5625C5.00651 14.5625 4.86363 14.6218 4.75828 14.7275C4.65293 14.8332 4.59375 14.9765 4.59375 15.126V26.3965C4.59375 26.546 4.65293 26.6893 4.75828 26.795C4.86363 26.9007 5.00651 26.96 5.15549 26.96H11.8964C12.0454 26.96 12.1882 26.9007 12.2936 26.795C12.3989 26.6893 12.4581 26.546 12.4581 26.3965V20.1977H16.952V26.3965C16.952 26.546 17.0112 26.6893 17.1166 26.795C17.2219 26.9007 17.3648 26.96 17.5138 26.96H24.2547C24.4037 26.96 24.5465 26.9007 24.6519 26.795C24.7572 26.6893 24.8164 26.546 24.8164 26.3965V15.126C24.8164 14.9765 24.7572 14.8332 24.6519 14.7275C24.5465 14.6218 24.4037 14.5625 24.2547 14.5625Z"
        stroke="currentColor"
      />
    </svg>
  );
}

export default HomeIcon;
