const routes = [
  // {
  //   text: 'Dashboard',
  //   href: '/app/dashboard',
  //   as: '/app/dashboard',
  //   privilege: [1],
  //   isCollapsible: false,
  //   icon: (
  //     <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 24 24">
  //       <path d="M0 0h24v24H0z" fill="none" />
  //       <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
  //     </svg>
  //   ),
  // },
  {
    text: 'Órdenes',
    href: '/app/orders',
    as: '/app/orders',
    privilege: [1, 2, 3, 4, 5],
    isCollapsible: false,
    icon: (
      <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 20 20">
        <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z" />
        <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z" />
      </svg>
    ),
  },
  {
    text: 'Productos',
    href: '/app/products',
    as: '/app/products',
    privilege: [1, 2, 3],
    isCollapsible: false,
    icon: (
      <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  // {
  //   text: 'Venta por Volumen',
  //   href: '/app/volume-sale',
  //   as: '/app/volume-sale',
  //   privilege: [1],
  //   isCollapsible: false,
  //   icon: (
  //     <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 24 24">
  //       <path d="M0 0h24v24H0z" fill="none" />
  //       <path d="M12 2l-5.5 9h11z" />
  //       <circle cx="17.5" cy="17.5" r="4.5" />
  //       <path d="M3 13.5h8v8H3z" />
  //     </svg>
  //   ),
  // },
  {
    text: 'Roles y Privilegios',
    href: '/app/users',
    as: '/app/users',
    privilege: [1],
    isCollapsible: false,
    icon: (
      <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    text: 'Clientes',
    href: '/app/clients',
    as: '/app/clients',
    privilege: [1, 2, 3],
    isCollapsible: false,
    icon: (
      <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 21 21">
        <path
          fillRule="evenodd"
          d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    text: 'Administración',
    privilege: [1],
    isCollapsible: true,
    icon: (
      <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
          clipRule="evenodd"
        />
      </svg>
    ),
    subLinks: [
      {
        text: 'Notas de Entregas',
        href: '/app/delivery-notes',
        as: '/app/delivery-notes',
      },
      {
        text: 'Impuesto',
        href: '/app/taxes',
        as: '/app/taxes',
      },
    ],
  },
  {
    text: 'Mantenimiento',
    privilege: [1],
    isCollapsible: true,
    icon: (
      <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 20 20">
        <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
        <path
          fillRule="evenodd"
          d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
          clipRule="evenodd"
        />
      </svg>
    ),
    subLinks: [
      {
        text: 'Categorías',
        href: '/app/categories',
        as: '/app/categories',
      },
      {
        text: 'Monedas',
        href: '/app/currencies',
        as: '/app/currencies',
      },
      {
        text: 'Promociones',
        href: '/app/promo-codes',
        as: '/app/promo-codes',
      },
      {
        text: 'Zonas de Entrega',
        href: '/app/shippings',
        as: '/app/shippings',
      },
      {
        text: 'Métodos de Pago',
        href: '/app/payment-methods',
        as: '/app/payment-methods',
      },
    ],
  },
  {
    text: 'Contenido',
    href: '/app/content',
    as: '/app/content',
    privilege: [1],
    isCollapsible: true,
    icon: (
      <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M2 6H0v5h.01L0 20c0 1.1.9 2 2 2h18v-2H2V6zm20-2h-8l-2-2H6c-1.1 0-1.99.9-1.99 2L4 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7 15l4.5-6 3.5 4.51 2.5-3.01L21 15H7z" />
      </svg>
    ),
    subLinks: [
      {
        text: 'Carousel',
        href: '/app/content/carousel',
        as: '/app/content/carousel',
      },
      {
        text: 'Plugins',
        href: '/app/content/plugins',
        as: '/app/content/plugins',
      },
      {
        text: 'Sobre nosotros',
        href: '/app/content/about-us',
        as: '/app/content/about-us',
      },
      {
        text: 'Términos y Condiciones',
        href: '/app/content/terms-of-service',
        as: '/app/terms-of-service',
      },
      {
        text: 'Preguntas Frecuentes',
        href: '/app/content/frequently-asked-questions',
        as: '/app/content/frequently-asked-questions',
      },
    ],
  },
];

export default routes;
