import { gql } from '@apollo/client';
import {
  ClientFragment,
  OrderFragment,
  ProductFragment,
  UserFragment,
} from './fragments';

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      success
      err
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      success
      err
    }
  }
`;

export const CHANGE_OLD_PASSWORD = gql`
  mutation CHANGE_OLD_PASSWORD($data: ChangeOldPasswordInput!) {
    changeOldPassword(data: $data) {
      success
      err
    }
  }
`;

// Session
export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut {
      success
    }
  }
`;

export const SIGN_IN = gql`
  mutation SIGN_IN($data: SignInInput!) {
    signIn(data: $data) {
      user {
        ...UserFragment
      }
      token
    }
  }
  ${UserFragment}
`;

export const SIGN_UP = gql`
  mutation SIGN_UP($data: SignUpInput!) {
    signUp(data: $data) {
      user {
        ...UserFragment
      }
      token
    }
  }
  ${UserFragment}
`;

// Address
export const CREATE_ADDRESS = gql`
  mutation CREATE_ADDRESS($data: CreateAddressInput!) {
    createAddress(data: $data) {
      _id
      state
      city
      municipality
      neighborhood
      street
      house
      reference
      type
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DELETE_ADDRESS($data: DeleteAddressInput!) {
    deleteAddress(data: $data) {
      _id
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UPDATE_ADDRESS(
    $record: UpdateOneAddressInput!
    $filter: FilterUpdateOneAddressInput
  ) {
    updateAddress(record: $record, filter: $filter) {
      record {
        _id
        state
        city
        municipality
        neighborhood
        street
        house
        reference
        type
      }
    }
  }
`;

// Client
export const UPDATE_CLIENT = gql`
  mutation UPDATE_CLIENT(
    $record: UpdateOneClientInput!
    $filter: FilterUpdateOneClientInput!
  ) {
    updateClient(record: $record, filter: $filter) {
      record {
        phone
        preferredAddress {
          _id
        }
      }
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DELETE_ACCOUNT($data: DeleteAccountInput!) {
    deleteAccount(data: $data) {
      success
      err
    }
  }
`;

// User
export const UPDATE_USER = gql`
  mutation UPDATE_USER(
    $record: UpdateOneUserInput!
    $filter: FilterUpdateOneUserInput!
  ) {
    updateUser(record: $record, filter: $filter) {
      record {
        name
        email
      }
    }
  }
`;

// Shopcart
export const ADD_ITEM_TO_SHOP_CART = gql`
  mutation ADD_ITEM_TO_SHOP_CART($data: AddItemToCartInput!) {
    addItemToCart(data: $data) {
      _id
      client {
        user {
          name
          slug
          _id
        }
      }
      items {
        _id
        product {
          ...ProductFragment
        }
        taxes {
          name
          value
        }
        quantity
        price
        photo
      }
      createdAt
      updatedAt
    }
  }
  ${ProductFragment}
`;

export const INCREASE_ONE_ITEM_OF_CART = gql`
  mutation INCREASE_ONE_ITEM_OF_CART($data: IncreaseItemOfCartInput!) {
    increaseOneItemOfCart(data: $data) {
      _id
      client {
        user {
          name
          slug
          _id
        }
      }
      items {
        _id
        product {
          ...ProductFragment
        }
        taxes {
          name
          value
        }
        quantity
        price
        photo
      }
      createdAt
      updatedAt
    }
  }
  ${ProductFragment}
`;

export const DECREASE_ONE_ITEM_OF_CART = gql`
  mutation DECREASE_ONE_ITEM_OF_CART($data: DecreaseItemOfCartInput!) {
    decreaseOneItemOfCart(data: $data) {
      _id
      client {
        user {
          name
          slug
          _id
        }
      }
      items {
        _id
        product {
          ...ProductFragment
        }
        taxes {
          name
          value
        }
        quantity
        price
        photo
      }
      createdAt
      updatedAt
    }
  }
  ${ProductFragment}
`;

export const REMOVE_ITEM_FROM_CART = gql`
  mutation REMOVE_ITEM_FROM_CART($data: RemoveItemFromCartInput!) {
    removeItemFromCart(data: $data) {
      _id
      client {
        user {
          name
          slug
          _id
        }
      }
      items {
        _id
        product {
          ...ProductFragment
        }
        taxes {
          name
          value
        }
        quantity
        price
        photo
      }
      createdAt
      updatedAt
    }
  }
  ${ProductFragment}
`;

export const CREATE_ORDER = gql`
  mutation CREATE_ORDER($data: CreateOrderInput!) {
    createOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;
